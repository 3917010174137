import reno from "../assets/proudcts-images/reno.jpeg";
import regalo from "../assets/proudcts-images/regalo.jpeg";
import copoNieveMini from "../assets/proudcts-images/copo-de-nieve-mini.jpeg";
import muñecoNieve from "../assets/proudcts-images/muñeco-de-nieve.jpeg";
import botaNavidad from "../assets/proudcts-images/bota-de-navidad.jpeg";
import arbolPino from "../assets/proudcts-images/arbol-pino.jpeg";
import papaNoel from "../assets/proudcts-images/papa-noel.jpeg";
import canastaVela from "../assets/proudcts-images/canasta-de-vela.jpeg";
import cuboEsferas from "../assets/proudcts-images/cubo-de-esferas.jpeg";
import arbolEspiral from "../assets/proudcts-images/arbol-espiral.jpeg";
import piramide from "../assets/proudcts-images/piramide.jpeg";
import luxe from "../assets/proudcts-images/luxe.jpeg";
import arcoiris from "../assets/proudcts-images/arcoiris.jpeg";
import velaCandelabro from "../assets/proudcts-images/vela-candelabro.jpeg";
import velaMujer from "../assets/proudcts-images/vela-mujer-flores-pelo.jpeg";
import copoNieve from "../assets/proudcts-images/copo-de-nieve.jpeg";
import arbolMini from "../assets/proudcts-images/arbol-mini.jpeg";
import arbolCapas from "../assets/proudcts-images/arbol-capas.jpeg";
import packsVelas from "../assets/proudcts-images/packs-velas.jpeg";
import luxePequeña from "../assets/proudcts-images/luxe-pequeña.jpeg";
import MuchoLove from "../assets/proudcts-images/mucho-love.jpeg";
import ArcoirisM from "../assets/proudcts-images/arcoíris-m.jpeg";
import OsitoTito from "../assets/proudcts-images/osito-tito.jpeg";
import Morita from "../assets/proudcts-images/morita.jpeg";
import Margarita from "../assets/proudcts-images/margarita.jpeg";
import MujerOjosVendados from "../assets/proudcts-images/mujer-ojos-vendados.jpeg";
import Rosa from "../assets/proudcts-images/rosa.jpeg";
import LuxeS from "../assets/proudcts-images/luxe-s.jpeg";

export default [
  {
    id: 1,
    name: "Luxe XS",
    dimensions: "5,5 cm x 6 cm",
    price: "3€",
    image: luxePequeña,
  },
  {
    id: 2,
    name: "Luxe S",
    dimensions: "9 cm x 8,5 cm",
    price: "8€",
    image: LuxeS,
  },
  {
    id: 3,
    name: "Luxe M",
    dimensions: "8,5 cm x 8 cm",
    price: "12,5€",
    image: luxe,
  },
  {
    id: 4,
    name: "Mucho love",
    dimensions: "6 cm x 6 cm",
    price: "8€",
    image: MuchoLove,
  },
  {
    id: 5,
    name: "Pirámide",
    dimensions: "12 cm x 8 cm",
    price: "7€",
    image: piramide,
  },
  {
    id: 6,
    name: "Osito Tito",
    dimensions: "8 cm x 6,5 cm",
    price: "7€",
    image: OsitoTito,
  },
  {
    id: 7,
    name: "Arcoíris S",
    dimensions: "11,5 cm x 6 cm",
    price: "6€",
    image: arcoiris,
  },
  {
    id: 8,
    name: "Arcoíris M",
    dimensions: "14 cm x 14 cm",
    price: "8€",
    image: ArcoirisM,
  },
  {
    id: 9,
    name: "Rosa",
    // dimensions: "3 cm x 3 cm",
    price: "5€",
    image: Rosa,
  },
  {
    id: 10,
    name: "Árbol espiral",
    dimensions: "9,5 cm x 7 cm",
    price: "6€",
    image: arbolEspiral,
  },
  {
    id: 11,
    name: "Árbol capas",
    dimensions: "8,5 cm x 6 cm",
    price: "4€",
    image: arbolCapas,
  },
  {
    id: 12,
    name: "Candelabro",
    dimensions: "15 cm x 4,5 cm",
    price: "5€",
    image: velaCandelabro,
  },
  {
    id: 13,
    name: "Copo de nieve",
    dimensions: "6,5 cm x 2,5 cm",
    price: "4€",
    image: copoNieve,
  },
  {
    id: 14,
    name: "Morita",
    dimensions: "5,8 cm x 6,5 cm",
    price: "5€",
    image: Morita,
  },
  {
    id: 15,
    name: "Mujer flores en el pelo",
    dimensions: "9 cm x 7 cm",
    price: "5€",
    image: velaMujer,
  },
  {
    id: 16,
    name: "Mujer ojos vendados",
    // dimensions: "3 cm x 3 cm",
    price: "5€",
    image: MujerOjosVendados,
  },
  {
    id: 17,
    name: "Árbol mini",
    dimensions: "5 cm x 6 cm",
    price: "2€",
    image: arbolMini,
  },
  {
    id: 18,
    name: "Cubo de esferas",
    dimensions: "4 cm x 4 cm",
    price: "3€",
    image: cuboEsferas,
  },
  {
    id: 19,
    name: "Reno",
    dimensions: "4cm x 3,5 cm",
    price: "3€",
    image: reno,
  },
  {
    id: 20,
    name: "Margarita",
    // dimensions: "3 cm x 3 cm",
    price: "2€",
    image: Margarita,
  },
  {
    id: 21,
    name: "Regalo",
    dimensions: "5 cm x 5 cm",
    price: "3€",
    image: regalo,
  },
  {
    id: 22,
    name: "Copo de nieve mini",
    dimensions: "5,5 cm x 5 cm",
    price: "3€",
    image: copoNieveMini,
  },
  {
    id: 23,
    name: "Muñeco de nieve",
    dimensions: "5 cm x 6 cm",
    price: "3€",
    image: muñecoNieve,
  },
  {
    id: 24,
    name: "Bota Navidad",
    dimensions: "5,5 cm x 5,3 cm",
    price: "3€",
    image: botaNavidad,
  },
  {
    id: 25,
    name: "Pino",
    dimensions: "7 cm x 5,5 cm",
    price: "3€",
    image: arbolPino,
  },
  {
    id: 26,
    name: "Papá Noel",
    dimensions: "6 cm x 6 cm",
    price: "3€",
    image: papaNoel,
  },
  {
    id: 27,
    name: "Canasta de vela",
    dimensions: "6 cm x 3 cm",
    price: "3€",
    image: canastaVela,
  },
  {
    id: 28,
    name: "Packs de Velas",
    // dimensions: "6 cm x 3 cm",
    price: "20€",
    image: packsVelas,
  },
];
