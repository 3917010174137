import React from "react";
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import "./Contact.css";

const Contact = () => {
  const instagramHandle = "candleleshop";
  const phoneNumber = "+34695298247";

  return (
    <section id="contact-section">
      <div className="contact-container">
        <h1 className="contact-title">Contáctanos</h1>
        <p className="contact-description">
          ¿Tienes alguna duda o deseas hacer un pedido? ¡Estamos aquí para
          ayudarte!
        </p>

        <div className="contact-methods">
          <div className="contact-method">
            <h3>Instagram 📸</h3>
            <p>
              Síguenos y envíanos un mensaje directo en{" "}
              <a
                className="contact-link"
                href={`https://www.instagram.com/${instagramHandle}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                @{instagramHandle}
              </a>
              .
            </p>
          </div>
          <div className="contact-method">
            <h3>Teléfono 📞</h3>
            <p>
              Llámanos al{" "}
              <a className="contact-link" href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>{" "}
              para cualquier consulta o pedido.
            </p>
          </div>
          <div className="contact-method">
            <h3>WhatsApp 💬</h3>
            <p>
              Contáctanos en WhatsApp haciendo clic en{" "}
              <a
                className="contact-link"
                href={`https://wa.me/${phoneNumber.replace("+", "")}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {phoneNumber}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
