import React, { useState } from "react";
import { Link } from "react-scroll";
import CandleLeLogo from "../../assets/img/Candle-le - 1.JPG";
import "./HeaderComponent.css";

const HeaderComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header-container">
      <nav className="desktop-nav">
        <Link
          to="HomePage-section"
          smooth={true}
          duration={500}
          spy={true}
          offset={-120}
          activeClass="active-link"
          className="header-link"
        >
          <img
            className="logo-header"
            src={CandleLeLogo}
            alt="Candle-le logo"
          />
        </Link>
        <ul>
          <li>
            <Link
              to="about-section"
              smooth={true}
              duration={500}
              spy={true}
              offset={-100}
              activeClass="active-link"
              className="header-link"
            >
              Nuestras Velas
            </Link>
          </li>
          <li>
            <Link
              to="products-section"
              smooth={true}
              duration={500}
              spy={true}
              offset={-120}
              activeClass="active-link"
              className="header-link"
            >
              Productos
            </Link>
          </li>
          <li>
            <Link
              to="contact-section"
              smooth={true}
              duration={500}
              spy={true}
              offset={-120}
              activeClass="active-link"
              className="header-link"
            >
              Contacto
            </Link>
          </li>
        </ul>
      </nav>

      <nav className="hamburger-nav">
        <Link
          to="HomePage-section"
          smooth={true}
          duration={500}
          spy={true}
          offset={-100}
          activeClass="active-link"
          className="header-link"
        >
          <img
            className="logo-header"
            src={CandleLeLogo}
            alt="Candle-le logo"
          />
        </Link>
        <div className="hamburger-menu">
          <div
            className={`hamburger-icon ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={`menu-links ${menuOpen ? "open" : ""}`}>
            <li>
              <Link
                to="about-section"
                smooth={true}
                duration={500}
                spy={true}
                offset={-100}
                className="header-link"
                onClick={toggleMenu}
              >
                Nuestras Velas
              </Link>
            </li>
            <li>
              <Link
                to="products-section"
                smooth={true}
                duration={500}
                spy={true}
                offset={-100}
                className="header-link"
                onClick={toggleMenu}
              >
                Productos
              </Link>
            </li>
            <li>
              <Link
                to="contact-section"
                smooth={true}
                duration={500}
                spy={true}
                offset={-100}
                className="header-link"
                onClick={toggleMenu}
              >
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default HeaderComponent;
