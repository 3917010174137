import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import About from "./pages/About/About";
import Products from "./pages/Products/Product";
import "./App.css";
import Contact from "./pages/Contact/Contact";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent";

function App() {
  return (
    <div className="App">
      <HeaderComponent />
      <HomePage />
      <About />
      <Products />
      <Contact />
    </div>
  );
}

export default App;
