import React from "react";
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import CandleLeImage from "../../assets/img/candle-le-image.png";
import "./About.css";

const About = () => {
  return (
    <section id="about-section">
      <div className="about-container">
        <img
          className="candle-le-image"
          src={CandleLeImage}
          alt="Candle Image"
        />
        <div className="about-content">
          <h1 className="about-title">
            El Arte de la Luz: Velas hechas con Amor
          </h1>

          <div className="about-features">
            <div className="feature">
              <h3>Ingredientes Naturales 🌿</h3>
              <p>
                Cera de soja orgánica y mechas de algodón que le aportarán un
                toque puro y ecológico a tu hogar.
              </p>
            </div>
            <div className="feature">
              <h3>Detalles Únicos 💝</h3>
              <p>
                Hechas de forma artesanal con diseños exclusivos para embellecer
                tu hogar y crear un ambiente cálido y acogedor.
              </p>
            </div>
            <div className="feature">
              <h3>Regalo Perfecto 🎁</h3>
              <p>
                Un detalle ideal para sorprender y decorar cualquier espacio.
              </p>
            </div>
            <div className="feature">
              <h3>Sostenibilidad 🌍</h3>
              <p>
                Nuestras velas son eco-friendly y minimizan el impacto
                ambiental.
              </p>
            </div>
          </div>

          <div className="about-usage">
            <h2>Guía de Uso</h2>
            <p>
              Coloca la vela en una superficie estable y resistente al calor
              para una experiencia segura y prolongada.
            </p>
            <p>
              Evita encenderla en superficies inflamables y mantenla alejada de
              niños, y mascotas.
            </p>
            <p>Relájate, enciende una vela y deja que su luz te inspire.</p>

            <p className="about-disclaimer">
              **Nota**: Cada vela es única y artesanal. Al ser hechas con cera
              ecológica pueden presentar ligeras variaciones de textura, lo cual
              no afecta su calidad.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
