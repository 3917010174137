import React from "react";
import logo from "../../assets/img/Candle-le - 1.JPG";
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import "./HomePage.css";

const HomePage = () => {
  return (
    <section id="HomePage-section">
      <div className="hero-container">
        <div className="hero">
          <img src={logo} className="logo" alt="logo icon" />
          <h1>Luz que inspira</h1>
        </div>
      </div>
    </section>
  );
};

export default HomePage;
