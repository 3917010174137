import React, { useState } from "react";
import Slider from "react-slick";
import productsData from "../../data/products";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArcoirisCombinado from "../../assets/carrousel/arcoiris-combinado.jpeg";
import ArcoirisRojoBlanco from "../../assets/carrousel/arcoiris-rojo-blanco.jpeg";
import MoritaRojoBlanco from "../../assets/carrousel/morita-rojo-blanco.jpeg";
import ProductosCombinados from "../../assets/carrousel/productos-combinados.jpeg";
import "./Product.css";

const Products = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const carouselImages = [
    ArcoirisCombinado,
    MoritaRojoBlanco,
    ArcoirisRojoBlanco,
    ProductosCombinados,
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  const handleWhatsAppClick = (product) => {
    const phone = "+34695298247";
    const message = `Hola, estoy interesado en el producto: ${product.name}.`;
    const url = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };
  return (
    <section id="products-section">
      <h1 className="products-title">Nuestros Productos</h1>
      <p className="products-description">
        Explora nuestra colección de velas artesanales, cuidadosamente
        elaboradas para brindar calidez y fragancia a tu hogar.
      </p>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage}
              alt="Vista ampliada"
              className="modal-image"
            />
          </div>
        </div>
      )}
      <div className="products-container">
        {productsData.map((product) => (
          <div key={product.id} className="product-card">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
              onClick={() => openModal(product.image)}
            />
            <div className="product-info">
              <h3 className="product-name">{product.name}</h3>
              {product.dimensions && (
                <p className="product-dimensions">
                  Medidas: {product.dimensions}
                </p>
              )}
              <p className="product-price">Precio: {product.price}</p>
              {/* <button
                className="whatsapp-button"
                onClick={() => handleWhatsAppClick(product)}
              >
                Contactar por WhatsApp
              </button> */}
            </div>
          </div>
        ))}
      </div>
      <div className="info-card">
        <h2 className="info-title">🎨 ¡Personaliza tu Producto!</h2>
        <p className="info-text">
          Nuestros productos pueden realizarse en los colores que desees,
          incluso combinaciones únicas que reflejen tu estilo. ¡Deja volar tu
          creatividad y hazlo tuyo!
        </p>
      </div>
      <div className="carousel-container">
        <Slider {...sliderSettings}>
          {carouselImages.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Producto ${index + 1}`}
                className="carousel-image"
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Products;
